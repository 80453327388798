import React from 'react'
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { MdWest } from 'react-icons/md';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SimpleTemplate = ({ children, title, onViewPdf }) => {

    const history = useHistory()

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100 d-flex justify-contente-center'>
                        <Col className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={() => history.goBack()} />
                        </Col>
                        <Col className='text-center' xs={8}><b>{title}</b></Col>
                        <Col xs={2} className="justify-content-end d-flex">
                            {onViewPdf !== undefined && <FaPrint size={30} className='cursor-pointer mr-3' onClick={onViewPdf} />}
                        </Col>
                    </Row>
                </Container>
            </Navbar>
            {children}
        </>
    )
}

export default SimpleTemplate;