import React, { useEffect, useState } from 'react'
import SimpleTemplate from '../../template/simple.template';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { getDetailWorker, onCreatePayroll } from '../../Redux/payrollDuck'
import { getMethodPayments } from '../../Redux/paymentDuck'
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import ModalPayroll from './modal.payroll';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL


const CajaWorkerPage = ({ getDetailWorker, data_worker, fetching_worker, getMethodPayments, fetching_method, data_method, onCreatePayroll,
    fetching_create, fetching_active, row }) => {

    let { worker_id } = useParams()
    const [list, setList] = useState([])
    const [data, setData] = useState({})
    const [modal, setModal] = useState(false)
    const [listMethod, setListMethod] = useState([])
    const [dataTurn, setDataTurn] = useState({})

    useEffect(() => {
        getMethodPayments()
    }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            setModal(false)
            window.location.reload()
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_active === 2) {
            setDataTurn(row)
        }
    }, [fetching_active])

    useEffect(() => {
        if (fetching_method === 2) {
            setListMethod(data_method)
        }
    }, [fetching_method])

    useEffect(() => {
        if (fetching_worker === 2) {
            setData(data_worker)
            setList(data_worker.results)
        }
    }, [fetching_worker])

    useEffect(() => {
        getDetailWorker(worker_id)
    }, [worker_id])


    const openPayroll = () => {
        if (data.total_worker > 0) {
            setModal(true)
        } else {
            alert("No tienes valors que pagar")
        }

    }

    const onPayrolWorker = (item) => {
        let form = {}
        form.method_payment_id = item.id
        form.worker_id = worker_id
        form.payment_worker = data.total_worker
        onCreatePayroll(form)
    }

    return (
        <SimpleTemplate title={"Detalles de Trabajador "} onViewPdf={() => {
            let dateInit = moment().format("YYYY-MM-01")
            let dateEnd = moment().format("YYYY-MM-DD")
            let url = `${API_URL}/api/orders-operator-view/${worker_id}/${dateInit}/${dateEnd}/${dataTurn.id}?status_id=3`
            window.open(url, "_blank");
        }}>
            <Container fluid>
                <Row>
                    {list.map((d, k) => <Col lg={4} key={k}>
                        <Card bg={`${d.is_paid === true ? 'secondary' : ''}`}>
                            <Card.Body>
                                <Row>
                                    <Col>Fecha</Col>
                                    <Col className='text-end'>{d.finish_at}</Col>
                                </Row>
                                <Row>
                                    <Col>Orden</Col>
                                    <Col className='text-end'>{d.id}</Col>
                                </Row>
                                <Row>
                                    <Col>Orden del día</Col>
                                    <Col className='text-end'>{d.day}</Col>
                                </Row>
                                <Row className='border-top'>
                                    <Col>PLACA</Col>
                                    <Col className='text-end'>{d.plate}</Col>
                                </Row>
                                <Row className=''>
                                    <Col>Categoria</Col>
                                    <Col className='text-end'>{d.category}</Col>
                                </Row>
                                <Row>
                                    <Col>Servicio</Col>
                                    <Col className='text-end'>{d.service_vehicle}</Col>
                                </Row>
                                <Row>
                                    <Col>Método de Pago</Col>
                                    <Col className='text-end'>{d.method_payment}</Col>
                                </Row>
                                <Row>
                                    <Col>Precio</Col>
                                    <Col className='text-end'>{d.price}</Col>
                                </Row>
                                <Row>
                                    <Col>Descuento</Col>
                                    <Col className='text-end'>{d.discount}</Col>
                                </Row>
                                <Row>
                                    <Col>TOTAL SERVICIO</Col>
                                    <Col className='text-end'>{d.subtotal}</Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col className='font-weight-bold'>PORCENTAJE A PAGAR</Col>
                                    <Col className='text-end'>{d.percent}</Col>
                                </Row>
                                <Row>
                                    <Col className='font-weight-bold' lg={9}>SUBTOTAL A PAGAR POR SERVICIO</Col>
                                    <Col className='text-end'>{d.price_percent}</Col>
                                </Row>
                                <Row>
                                    <Col className='font-weight-bold' lg={9}>PROPINA</Col>
                                    <Col className='text-end'>{d.tip}</Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col className='font-weight-bold' lg={9}>PAGO A TRABAJADOR</Col>
                                    <Col className='text-end'>{d.total_payment_worker}</Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>)}
                </Row>
                <Row className='my-3 d-flex justify-content-center'>
                    <Col lg={5}>
                        <Card className='bg-card'>
                            <Card.Body>
                                <Row>
                                    <Col>CANTIDAD DE SERVICIOS</Col>
                                    <Col className='text-end'>{data.quantity}</Col>
                                </Row>
                                <Row>
                                    <Col>TOTAL GENERADO</Col>
                                    <Col className='text-end'>{data.total}</Col>
                                </Row>
                                <Row>
                                    <Col>PENDIENTE POR PAGAR</Col>
                                    <Col className='text-end'>{data.total_worker}</Col>
                                </Row>
                                <Row>
                                    <Col>TOTAL PAGADO</Col>
                                    <Col className='text-end'>{data.subtotal_worker_paid}</Col>
                                </Row>
                                <Row>
                                    <Col>PROPINA</Col>
                                    <Col className='text-end'>{data.tip}</Col>
                                </Row>
                                <Row>
                                    <Col>TOTAL DESCUENTOS</Col>
                                    <Col className='text-end'>{data.discount}</Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className='fixed-bottom d-flex justify-content-center'>
                    <Col className='my-4 py-3 text-center' lg={2}>
                        <button className='btn btn-success' onClick={openPayroll}>LIQUIDAR TRABAJADOR ${formatNumber(data.total_worker)} </button>
                    </Col>
                </Row>
                <ModalPayroll show={modal}
                    onHide={() => setModal(false)}
                    listMethod={listMethod}
                    data={data}
                    onSuccess={onPayrolWorker} />
            </Container>
        </SimpleTemplate>

    )
}

const mapsState = (props) => {
    let { payroll, payment, turn } = props

    console.log('props', props);

    return {
        fetching_worker: payroll.fetching_worker,
        data_worker: payroll.data_worker,
        fetching_method: payment.fetching_method,
        data_method: payment.data_method,
        fetching_create: payment.fetching_create,
        fetching_active: turn.fetching_active,
        row: turn.row,
    }
}

export default connect(mapsState, { getDetailWorker, getMethodPayments, onCreatePayroll })(CajaWorkerPage);